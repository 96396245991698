import React from "react"
import { graphql, Link } from "gatsby"
import { EventCard, Layout, SEO } from "../components"

const PerformancePostTemplate = ({ data, location }) => {
  const post = data.markdownRemark.frontmatter
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.performance_name} description={post.description} />
      <article className=" [ wrapper-narrow ] ">
        <EventCard
          date={post.displaydate}
          day={post.day}
          month={post.month}
          year={post.year}
          performance_name={post.performance_name}
          company={post.company}
          ticket_link={post.ticket_link}
          time={post.time}
          preview_image={post.preview_image.childImageSharp.fluid}
          more_details={false}
        />
        <section
          className="markdown [ section ] [ flow ] "
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
        <div className="back-to-somewhere">
          <Link to="/performances/" className="[ link-hover ]">
            Performances
          </Link>
        </div>
      </article>
    </Layout>
  )
}

export default PerformancePostTemplate

export const pageQuery = graphql`
  query PerformancePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        performance_name
		company
		date(formatString: "YYYY-MM-DD")
		displaydate: date(formatString: "D MMMM YYYY")
		year: date(formatString: "YYYY")
		month: date(formatString: "MMM")
		day: date(formatString: "D")
        time
        ticket_link
        description
        preview_image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
